import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-felloh-failure',
  templateUrl: './felloh-failure.component.html',
  styleUrls: ['./felloh-failure.component.css', '../../customerFace.css']
})
export class FellohFailureComponent implements OnInit {

  branchName: any = '';
  branchLogo: any = '';
  status: any = '';
  paymentId: any = '';

  constructor(private router: Router,  private location: Location,
              private route: ActivatedRoute) { this.location.replaceState(''); }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.branchName !== undefined && params.branchLogo !== undefined && params.status) {
        this.branchName = decodeURI(params.branchName);
        this.branchLogo = decodeURI(params.branchLogo);
        this.status = decodeURI(params.status);
        this.paymentId = decodeURI(params.paymentId);
      } else {
        this.router.navigate(['']);
      }
    });
  }

}
