import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bad-request',
  templateUrl: './bad-request.component.html',
  styleUrls: ['./bad-request.component.css', '../../customerFace.css']
})
export class BadRequestComponent implements OnInit {

  constructor(private location: Location) { this.location.replaceState(''); }

  ngOnInit(): void {

  }

}
